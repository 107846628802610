.aipopupcomponent-default-ai-felulet-gomb-chat-wrap {
justify-content: flex-end;
align-items: flex-end;
min-width: 23vw;
min-height: 65vh;
display: flex;
position: relative;
overflow: hidden;
right: 40px;
bottom: 30px;
}
.aipopupcomponent-default-felulet-btn {
background-color: #282f32;
background-image: linear-gradient(69deg, darkblue, #3898ec);
border-radius: 50%;
flex-flow: column;
justify-content: center;
align-items: center;
width: 3.5vw;
height: 3.5vw;
display: flex;
}
.aipopupcomponent-default-ai-pop-up-wrap {
justify-content: flex-end;
align-items: flex-end;
width: 100vw;
height: 100vh;
padding-bottom: 2rem;
padding-right: 2rem;
display: flex;
}
.aipopupcomponent-default-ai-image {
width: 1.7vw;
}
.aipopupcomponent-default-rendes-chat-felulet-skipped-wrap {
grid-column-gap: 16px;
grid-row-gap: 16px;
background-image: url('./aiimages/Dots.png'), linear-gradient(146deg, #303d3d, #181819);
background-position: 0 0, 0 0;
background-size: cover, auto;
border-radius: 15px;
flex-flow: column;
grid-template-rows: auto auto;
grid-template-columns: 1fr 1fr;
grid-auto-columns: 1fr;
justify-content: space-between;
align-items: flex-start;
width: 23vw;
height: 65vh;
padding: 1rem;
display: flex;
position: absolute;
inset: 0%;
}
.aipopupcomponent-default-gma-ai-logo-skip-txt-copy {
grid-column-gap: 1rem;
grid-row-gap: 1rem;
flex-flow: row;
justify-content: space-between;
align-items: flex-start;
width: 100%;
padding-bottom: 0;
display: flex;
}
.aipopupcomponent-default-div-block-4-ai {
grid-column-gap: 1vw;
grid-row-gap: 1vw;
border-radius: 50%;
justify-content: flex-start;
align-items: center;
display: flex;
}
.aipopupcomponent-default-div-block-19 {
cursor: pointer;
mix-blend-mode: normal;
justify-content: center;
align-items: center;
width: 1.7vw;
height: 1.7vw;
display: flex;
}
.aipopupcomponent-default-image-2 {
width: 1.7vw;
height: 1.7vw;
top: 0;
left: 0;
position: absolute;
}
.aipopupcomponent-default-text-block-2 {
color: #fff;
text-transform: uppercase;
cursor: auto;
font-family: Poppins, sans-serif;
font-size: 1vw;
font-weight: 400;
line-height: 1;
}
.aipopupcomponent-default-close-chat {
cursor: pointer;
justify-content: center;
align-items: center;
width: 1vw;
height: 1vw;
display: flex;
}

.aipopupcomponent-default-beszelgetes-wrap {
grid-column-gap: 0vw;
grid-row-gap: 0vw;
flex-flow: column;
width: 100%;
height: 100%;
display: flex;
overflow: clip;
}
.aipopupcomponent-default-ai-sent-message {
grid-column-gap: .5vw;
grid-row-gap: .5vw;
background-color: #f4f5f5;
border-radius: 15px;
justify-content: flex-start;
align-items: flex-start;
padding: .5rem;
display: flex;
}
.aipopupcomponent-default-div-block-11 {
background-image: linear-gradient(69deg, darkblue, #3898ec);
border: .5px solid #000;
border-radius: 50%;
justify-content: center;
align-items: center;
width: 2vw;
min-width: 2vw;
height: 2vw;
display: flex;
overflow: hidden;
}
.aipopupcomponent-default-image-3 {
width: 1vw;
height: 1vw;
}
.aipopupcomponent-default-message-content-wrap {
grid-column-gap: 1vw;
grid-row-gap: 1vw;
flex-flow: column;
width: 100%;
padding-top: 0;
display: flex;
}
.aipopupcomponent-default-paragraph {
margin-bottom: 0;
font-family: Roboto, sans-serif;
font-size: .9vw;
line-height: 1.3;
}
.aipopupcomponent-default-div-block-14 {
grid-column-gap: 1vw;
grid-row-gap: 1vw;
justify-content: flex-start;
align-items: center;
display: flex;
}
.aipopupcomponent-default-div-block-12 {
grid-column-gap: .5vw;
grid-row-gap: .5vw;
background-color: #c9c9c9;
color: black;
border-radius: 15px;
justify-content: flex-start;
align-items: flex-start;
margin-bottom: 1vw;
padding: .5rem;
display: flex;
}
.aipopupcomponent-default-div-block-13 {
background-color: #f4f5f5;
border-radius: 50%;
width: 2vw;
min-width: 2vw;
height: 2vw;
}
.aipopupcomponent-default-paragraph-2 {
color: #000000;
margin-bottom: 0;
font-family: Roboto, sans-serif;
font-size: .9vw;
line-height: 1.3;
}
.aipopupcomponent-default-div-block-7 {
width: 100%;
}
.aipopupcomponent-default-form-block {
margin-bottom: 0;
}
.aipopupcomponent-default-form {
grid-column-gap: .5vw;
grid-row-gap: .5vw;
justify-content: space-between;
align-items: center;
display: flex;
}
.aipopupcomponent-default-div-block-9 {
width: 100%;
}
.aipopupcomponent-default-text-field {
color: #000000;
background-color: #dfdfdf;
border: 1px #000;
border-radius: 20px;
width: 100%;
margin-bottom: 0;
padding: .5vw 1vw;
font-family: Roboto, sans-serif;
font-size: .8vw;
line-height: 1;
}
.aipopupcomponent-default-text-field::placeholder {
color: #000000;
}
.aipopupcomponent-default-form_text-field {
color: #222;
background-color: #f4f6f9;
border: .0625rem solid #fff0;
border-radius: .625rem;
margin-bottom: 1.25rem;
font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
font-size: 1vw;
font-weight: 600;
}
.aipopupcomponent-default-form_text-field::placeholder {
font-weight: 500;
}
.aipopupcomponent-default-send-btn {
background-color: #fff;
border-radius: 50%;
justify-content: center;
align-items: center;
width: 2.5vw;
min-width: 2.5vw;
height: 2.5vw;
min-height: 2.5vw;
display: flex;
position: relative;
}
.aipopupcomponent-default-div-block-20 {
justify-content: center;
align-items: center;
display: flex;
position: relative;
}
.aipopupcomponent-default-send-img {
width: 1.3vw;
height: 1.3vw;
transform: translate(5%);
}
.aipopupcomponent-default-ai-image-5 {
width: 1.3vw;
height: 1.3vw;
position: absolute;
right: auto;
transform: translate(5%);
}
@media screen and (max-width: 479px) {
    .aipopupcomponent-default-ai-theedot {
        transform: scale(0.7) translateX(-8px);
    }
    .aipopupcomponent-default-ai-theedot-holder {
        transform: scale(0.7) translate(-15px, -20px);
        min-width: 70px;;
    }
    .aipopupcomponent-default-ai-pop-up-wrap {
        padding-bottom: 1rem;
        padding-right: 1rem;
        
        }
        .aipopupcomponent-default-ai-image {
        width: 15px;
        }
        .aipopupcomponent-default-ai-felulet-gomb-chat-wrap {
        min-width: 80%;
        min-height: 60vh;
        right: 5px;
        bottom: 50px;
        }
    
        .aipopupcomponent-default-felulet-btn {
        width: 35px;
        height: 35px;
        }
        .aipopupcomponent-default-rendes-chat-felulet-skipped-wrap {
        width: 100%;
        height: 60vh;
        display: none;
        }
        .aipopupcomponent-default-gma-ai-logo-skip-txt-copy {
        flex-flow: row;
        }
        .aipopupcomponent-default-div-block-4-ai {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        }
        .aipopupcomponent-default-div-block-19 {
        margin-right: 15px;
        }
        .aipopupcomponent-default-image-2 {
        width: 20px;
        height: 20px;
        }
        .aipopupcomponent-default-text-block-2 {
        font-size: .9rem;
        }
        .aipopupcomponent-default-close-chat {
        width: 15px;
        height: 15px;
        }
        .aipopupcomponent-default-ai-sent-message {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        }
        .aipopupcomponent-default-div-block-11 {
        width: 20px;
        min-width: 20px;
        height: 20px;
        }
        .aipopupcomponent-default-image-3 {
        width: 10px;
        height: 10px;
        }
        .aipopupcomponent-default-message-content-wrap {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        }
        .aipopupcomponent-default-paragraph {
        font-size: .7rem;
        }
        .aipopupcomponent-default-div-block-14 {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        }
        .aipopupcomponent-default-div-block-12 {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        margin-bottom: 1rem;
        }
        .aipopupcomponent-default-div-block-13 {
        width: 20px;
        min-width: 20px;
        height: 20px;
        }
        .aipopupcomponent-default-paragraph-2 {
        font-size: .7rem;
        }
        .aipopupcomponent-default-form {
        grid-column-gap: .5rem;
        grid-row-gap: .5rem;
        }
        .aipopupcomponent-default-send-btn {
        width: 20px;
        min-width: 35px;
        min-height: 35px;
        }    
        .aipopupcomponent-default-send-img {
        width: 15px;
        height: 15px;
        }
        .aipopupcomponent-default-text-field {
        color: #000000;
        background-color: #dfdfdf;
        border: 1px #000;
        border-radius: 20px;
        width: 100%;
        margin-bottom: 0;
        padding: .5vw 1vw;
        font-family: Roboto, sans-serif;
        font-size: .8vw;
        line-height: 1;
        font-size: 12px;
        }
        .aipopupcomponent-default-text-field::placeholder {
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        }
}
